<template>
  <div class="wrapper">
    <headBack>
      <template v-slot:title>
        <div class='title'>
          地址列表
        </div>
      </template>
    </headBack>
    <div class='header_cont'></div>
    <div class="no_box"
         v-if="flag"
    >
      <img src="https://static.yihu365.cn/img/h5Img/shop/no_message.png" alt="">
      <div>快添加个新地址吧~ </div>
    </div>
      <div class="add_box" @click="onAdd">
         <div class="btn">新增地址</div>
      </div>
      <div class="lists"  v-if="!flag">
        <div class="address_box" v-for="(item,index) in list">
          <div class="cont" @click="clickItem(item)">
            <div><span class="name">{{ item.name }}</span><span class="phone">{{setTel(item.tel)}}</span></div>
            <div class="address">{{item.address}}</div>
          </div>
          <div class="icon_box">
            <van-icon @click="toAddressEdit(item)" class="edit" name="edit" color="#999" size="24"/>
            <van-icon @click="onDelete(item)" name="delete-o" color="#999" size="24"/>
          </div>
        </div>
      </div>

    </div>
<!--  </div>-->
</template>

<script>
import {setWxShareDate} from '@/libs/utils.js'
import {onMounted, ref, computed, reactive} from "vue"
import {useRouter, useRoute} from 'vue-router'
import {useStore} from 'vuex'
import {Dialog,Toast} from 'vant'
import shopApi from '@axios/shop'


export default {
  name: "addressEdit",
  setup() {
    let router = useRouter();
    const store = useStore();
    const chosenAddressId = ref('1');
    const list = ref('')
    const flag = ref(false)

    const onAdd = () => {
      router.push({
         name:'addressEdit'
      })
    }
    const onEdit = (item, index) => Toast('编辑地址:' + index);

    const addressList  = ref('')
    const getAddList = () => {
      const params ={
        userId : store.state.uid
      }
      shopApi.getAddressList(params).then((res)=> {
        console.log(res)
        addressList.value = res.data;
        list.value = setList(addressList.value)
        if(res.data.length == 0){
          flag.value = true;
        }
      })
    }
    getAddList()


    const setList = (data) => {
       const newList =[];
       data.forEach((item,index)=> {
            let obj ={
               id:item.addressId,
               tel:item.contactNumber,
               name:item.consignee,
               address:item.detailedAddress + item.addressDetail,
               // isDefault:index == 0
            }
           newList.push(obj)
       });
       return newList;
    }
    const clickItem = (res) => {
    let name = sessionStorage.getItem('fromUrl')
      if(name =='order'){
        router.push({
          name:'order',
          query:{
            address:res.address,
            addressId:res.id,
            name:res.name,
            tel:res.tel
          }
        })
      }else{
        router.push({
          name:'shop'
        })
      }

    }
    const toAddressEdit = (res) => {
      router.push({
        name: 'addressEdit',
        query: {
          addressId : res.id
        }
      })
    }
   const setTel = (tel) => {
     let reg = /^(\d{3})\d{4}(\d{4})$/;
     return tel.replace(reg, "$1****$2");

   }

   const onDelete = (item)=> {
      const params ={
         addressId:item.id,
         userId:store.state.uid
      }
     Dialog.confirm({
       confirmButtonColor:'#00c291',
       title: '',
       message:
           '确定要删除该地址吗？',
     })
         .then(() => {
           // on confirm
           shopApi.getAddressDelete(params).then((res)=> {
             console.log();
             if(res.code == '0000'){
               Toast('删除成功')
               getAddList();
             }
           })
         })
         .catch(() => {
           // on cancel
         });
   }
    return {
      list,
      onAdd,
      onEdit,
      chosenAddressId,
      setList,
      toAddressEdit,
      clickItem,
      flag,
      setTel,
      onDelete
    };
  },
};


</script>

<style scoped lang="scss">
.wrapper{
  background: #f2f4f5;
  min-height: 100vh;


}
.add_box{
  width: 92%;
  margin:30px auto;
  background: linear-gradient(to bottom right, #25c011 0%, #00c291 100%);
  text-align: center;
  color:#fff;
  font-size: 32px;
  line-height: 80px;
  border-radius: 40px;
}
.lists{
  margin:0 auto;
  width: 95%;
  border-radius: 12px;
  box-sizing: border-box;
  padding:10px 20px;
  background: #fff;
}
.address_box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding:24px 10px;
  .cont{
    flex-grow: 1;
  }
  //border-bottom: 1px solid #d9d9d9;
  .icon_box{
    display: flex;
    justify-content: right;
    width:200px;
    text-align: right;
    .edit{
      margin-right: 24px;
    }
  }
  .name{
    color:#666;
    font-weight: bold;
    font-size: 32px;
  }
  .phone{
    color:#999;
    font-size: 28px;
    margin-left: 20px;
  }
  .address{
    color:#666;
    font-size: 28px;
    margin-top: 8px;
  }
}
::v-deep(.van-dialog__confirm){
  color:#00c291!important;
}
::v-deep(.van-address-item .van-radio__icon--checked .van-icon) {
  background: #00c291;
  border-color:#00c291
}
::v-deep(.van-tag--danger){
  background:#00c291 ;
}
::v-deep(.van-button--danger){
  background: #00c291;
  border-color:#00c291
}
.van-address-edit{
  padding:10px;
}
.no_box{
  padding-bottom: 20px;
  text-align: center;
  font-size: 26px;
  img{
    width: 300px;
  }
}
</style>
