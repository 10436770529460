import { render } from "./addressList.vue?vue&type=template&id=35a14117&scoped=true"
import script from "./addressList.vue?vue&type=script&lang=js"
export * from "./addressList.vue?vue&type=script&lang=js"

import "./addressList.vue?vue&type=style&index=0&id=35a14117&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-35a14117"
/* hot reload */
if (module.hot) {
  script.__hmrId = "35a14117"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('35a14117', script)) {
    api.reload('35a14117', script)
  }
  
  module.hot.accept("./addressList.vue?vue&type=template&id=35a14117&scoped=true", () => {
    api.rerender('35a14117', render)
  })

}

script.__file = "src/views/shop/order/address/addressList.vue"

export default script